export const contactUsValidations = {
  email: {
    required: 'You must provide an email address',
    pattern: {
      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'You must provide a valid email address',
    },
  },
  query: {
    required: 'You must provide an inquiry',
  },
};
